@import '@aurora/shared-client/styles/_variables.pcss';

.lia-blog-container {
  max-width: var(--lia-one-column-narrow-width);
  margin: 0 auto;

  .lia-message-body-container {
    margin-bottom: 20px;

    @media (--lia-breakpoint-down-xs) {
      margin-left: calc(var(--lia-bs-grid-gutter-width) * -0.5);
      margin-right: calc(var(--lia-bs-grid-gutter-width) * -0.5);
    }
  }
  &.lia-wide-overlay-title-bottom {
    margin-top: calc(-1 * #{$lia-section-spacing});
  }
  @media (--lia-breakpoint-down-xs) {
    margin: calc(-1 * #{$lia-section-spacing}) 0 0;
  }
}
.lia-blog-content {
  padding: 0 60px;

  &.lia-blog-content-overlay {
    transform: translateY(-65px);
    margin-bottom: -65px;
    position: relative;
    z-index: 2;
    padding-top: 25px;
    border-radius: var(--lia-bs-border-radius);
    background-color: var(--lia-panel-bg-color);

    @media (--lia-breakpoint-down-md) {
      border-radius: 0;
      transform: translateY(0);
      margin-bottom: 0;
      padding-top: 0;
    }
  }
  @media (--lia-breakpoint-down-md) {
    padding: 0 20px;
  }
}

.lia-article {
  padding: 0 0 60px;

  @media (--lia-breakpoint-down-md) {
    padding: 0 0 40px;
  }
  @media (--lia-breakpoint-down-xs) {
    border: unset;
    border-bottom: var(--lia-content-item-border);
  }
}

@media (--lia-breakpoint-up-sm) {
  .lia-reply-cta-wrapper {
    margin-left: 25px;
  }
}
